import React, { memo } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { styled } from '@mui/material'

// helpers
import slugPrefix from '@helpers/slugPrefix'

interface Props {
  slug: string
  apiKey: string
  label: string
}

const StyledButton = styled(GatsbyLink)(({ theme }) => ({
  display: 'inline-block',
  backgroundColor: theme.palette.common.black,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginRight: theme.spacing(0.8),
  marginBottom: theme.spacing(0.8),
  fontSize: '1.6rem',
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(1.6),
    paddingRight: theme.spacing(1.6),
  },
}))

const TagButton = ({ slug, apiKey, label }: Props) => {
  return (
    <StyledButton to={slugPrefix({ slug: slug, apiKey: apiKey })}>
      {label}
    </StyledButton>
  )
}

export default memo(TagButton)
