// npm
import React from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { Box, Container, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// root
import { pageContextProps } from '../../../gatsby-node'

// components
// import FilterBar from '@molecules/filters/FilterBar'
import TextContent from '@molecules/text/TextContent'
import Layout from '@organisms/global/Layout'

// constants
import { firstSectionSpacing } from '@constants/firstSectionSpacing'

type projectNode = {
  node: Queries.DatoCmsProject
}

interface Props {
  type: 'project'
  allItems: projectNode[]
  children: React.ReactNode
  landingData: Queries.DatoCmsLandingPage
  categoryLandingData?: Queries.DatoCmsService
  pageContext: PageProps & pageContextProps
}

const LandingLayout = ({
  type,
  allItems,
  children,
  landingData,
  // categoryLandingData,
  pageContext,
}: Props) => {
  const theme = useTheme()

  const data = useStaticQuery<Queries.LandingLayoutQuery>(graphql`
    query LandingLayout {
      allDatoCmsService(sort: { fields: position, order: ASC }) {
        edges {
          node {
            slug
            name
            originalId
            model {
              apiKey
            }
          }
        }
      }
    }
  `)
  const allFilters = data.allDatoCmsService.edges
  let activeFilters: Queries.DatoCmsService[] = []
  allFilters.map((item: any) => {
    const filter: Queries.DatoCmsService = item.node
    const filtered = allItems.filter((filterItem: any) => {
      if (type === 'project') {
        const project = filterItem.node as Queries.DatoCmsProject
        return project.services.some(
          (projectService: Queries.DatoCmsService) => {
            return projectService.originalId === filter.originalId
          }
        )
      }
    })
    if (filtered.length > 0) {
      activeFilters.push(filter)
    }
  })

  // let seoTags = {
  //   seoTags: landingData.seoMetaTags,
  // }
  // if (categoryLandingData) {
  //   seoTags = {
  //     ...seoTags,
  //     titlePrefix: categoryLandingData.name,
  //     customDescription: categoryLandingData[`${type}Text`],
  //   }
  // }
  return (
    // <Layout activeNavSlug={pageContext.activeNavSlug} metadata={seoTags}>
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      wrapperProps={{ bgcolor: 'secondary.main' }}
    >
      <Container
        maxWidth="xl"
        sx={{
          pt: firstSectionSpacing,
          paddingBottom: theme.spacing(10),
        }}
      >
        <Grid container spacing={0} sx={{ marginBottom: theme.spacing(6) }}>
          <Grid xs={12} lg={6}>
            <TextContent
              smallHeading={landingData.smallHeading}
              mainHeading={landingData.mainHeading}
              text={landingData.text}
            />
          </Grid>
        </Grid>

        {/* <FilterBar
          activeFilters={activeFilters}
          filterId={pageContext.filterId}
          pathPrefix={pageContext.activeNavSlug}
        /> */}
        <Box component="section" pb={{ xs: 3, md: 4, lg: 5 }}>
          {children}
        </Box>
      </Container>
    </Layout>
  )
}

export default LandingLayout
