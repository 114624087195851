// npm
import React from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'

// root
import { pageContextProps } from '../../../gatsby-node'

// components
import Pagination from '@molecules/pagination/Pagination'
import ProjectGrid from '@organisms/cardGrids/ProjectGrid'
import LandingLayout from './LandingLayout'

type ProjectNode = {
  node: Queries.DatoCmsProject
}

interface Props {
  projects: ProjectNode[]
  categoryData?: Queries.DatoCmsProjectFilteredService
  pageContext: PageProps & pageContextProps
}

const ProjectLandingLayout = ({
  projects,
  categoryData,
  pageContext,
}: Props) => {
  const data = useStaticQuery<Queries.ProjectLandingLayoutQuery>(graphql`
    query ProjectLandingLayout {
      datoCmsLandingPage(originalId: { eq: "133060184" }) {
        ...landing
      }
      allProjects: allDatoCmsProject {
        edges {
          node {
            services {
              originalId
            }
          }
        }
      }
    }
  `)

  return (
    <LandingLayout
      type="project"
      pageContext={pageContext}
      allItems={data.allProjects.edges}
      landingData={data.datoCmsLandingPage}
      categoryLandingData={categoryData}
    >
      <ProjectGrid projects={projects} />
      {/* <Pagination {...pageContext} /> */}
    </LandingLayout>
  )
}

export default ProjectLandingLayout
