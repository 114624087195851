// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import ProjectLandingLayout from '@templates/layouts/ProjectLandingLayout'

interface Props {
  data: Queries.ProjectLandingQuery
  pageContext: PageProps & pageContextProps
}

const ProjectLanding = ({ data, pageContext }: Props) => {
  return (
    <ProjectLandingLayout
      projects={data.allDatoCmsProject.edges}
      pageContext={pageContext}
    />
  )
}

export const query = graphql`
  query ProjectLanding($skip: Int!, $perPage: Int!) {
    allDatoCmsProject(
      limit: $perPage
      skip: $skip
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          ...projectGridCard
        }
      }
    }
  }
`

export default ProjectLanding
