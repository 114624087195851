// npm
import React from 'react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, StackProps, styled, useTheme } from '@mui/material'
import { HiArrowRight } from 'react-icons/hi'
import useResizeObserver from 'use-resize-observer'

import TagButton from '@atoms/buttons/TagButton'
import Frame from '@atoms/media/Frame'
import ImageHover from '@atoms/media/ImageHover'

// helpers
import slugPrefix from '@helpers/slugPrefix'

interface Props extends StackProps {
  image: IGatsbyImageData
  tintImage: IGatsbyImageData
  clientName: string
  services: Queries.DatoCmsService[]
  title: string
  slug: string
  apiKey: string
}

interface HoverProps {
  active?: boolean
}

const StyledGatsbyLink = styled(GatsbyLink, {
  shouldForwardProp: (prop) => prop !== 'active',
})<HoverProps>(({ active, theme }) => ({
  ...(active && {
    color: theme.palette.primary.main,
  }),
}))
const HeadingArrow = styled(HiArrowRight)(({ theme }) => ({
  display: 'inline-block',
  fontSize: '1.6rem',
  marginLeft: '0.2rem',
}))

const ProjectGridCard = ({
  image,
  tintImage,
  clientName,
  services,
  title,
  slug,
  apiKey,
}: Props) => {
  const theme = useTheme()
  const [isHover, setIsHover] = React.useState(false)
  const {
    ref: contentRef,
    width: contentWidth = 1,
    height: contentHeight = 1,
  } = useResizeObserver<HTMLDivElement>({ box: 'border-box' })

  const {
    ref: servicesRef,
    width: servicesWidth = 1,
    height: servicesHeight = 1,
  } = useResizeObserver<HTMLDivElement>({
    box: 'border-box',
  })

  const url = slugPrefix({
    slug: slug,
    apiKey: apiKey,
  })
  return (
    <Frame
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        position: 'relative',
        paddingBottom: `${contentHeight}px`,
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <ImageHover
        colorImage={image}
        tintImage={tintImage}
        forceHover={isHover}
        sx={{ cursor: 'hand' }}
        onClick={() => navigate(url)}
        bgColor="secondary.main"
        flipHover
      />

      <Box
        sx={{
          position: 'absolute',
          bottom: `-${isHover ? 0 : servicesHeight}px`,
          backgroundColor: 'secondary.dark',
          width: '100%',
          left: 0,
          px: 4,
          transition: theme.transitions.create('bottom'),
        }}
      >
        <Box py={4} ref={contentRef}>
          <Box
            component="h3"
            typography="lgBody"
            fontWeight="fontWeightMedium"
            color="white"
            my={0}
          >
            <StyledGatsbyLink to={url} active={isHover}>
              {clientName} <HeadingArrow />
            </StyledGatsbyLink>
          </Box>
          {title}
        </Box>

        <Box pb={4} ref={servicesRef}>
          {services.map((service: Queries.DatoCmsService, index: number) => {
            return (
              <TagButton
                key={`project-detail-tag-${service.originalId}-${index}}`}
                slug={service.slug}
                apiKey={service.model.apiKey}
                label={service.name}
              />
            )
          })}
        </Box>
      </Box>
    </Frame>
  )
}

export default React.memo(ProjectGridCard)
