// npm
import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// components
import ProjectGridCard from '@molecules/cards/ProjectGridCard'

type projectNode = {
  node: Queries.DatoCmsProject
}

interface Props {
  projects: projectNode[]
}

const ProjectGrid = ({ projects }: Props) => {
  const theme = useTheme()
  const breakpoint = 'xl'
  const breakpointUp = useMediaQuery(theme.breakpoints.up(breakpoint))

  return (
    <Grid
      container
      spacing={breakpointUp ? 4 : 2}
      justifyContent={projects.length < 4 ? 'center' : 'flex-start'}
    >
      {projects.map((item: projectNode) => {
        const project: Queries.DatoCmsProject = item.node
        return (
          <Grid xs={12} md={6} key={project.originalId}>
            <ProjectGridCard
              title={project.title}
              clientName={project.client.name}
              image={project.image.gatsbyImageData}
              tintImage={project.tintImage.gatsbyImageData}
              services={project.services}
              slug={project.slug}
              apiKey={project.model.apiKey}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ProjectGrid
